import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Estado } from '../interfaces/estado';
import {environment} from '../../environments/environment';
import { Observable } from 'rxjs';
import { EstadoResource } from '../interfaces/estado-resource';

@Injectable({
  providedIn: 'root'
})
export class EstadosService {

  constructor(private http: HttpClient) { }

  public getEstados(): Observable<EstadoResource>
  {
    return this.http.get<EstadoResource>(`${environment.api}/api/estados`);
  }

  public persistEstados(Estado: Estado)
  {
    return this.http.post(`${environment.api}/api/estados`, Estado);
  }

  public validaEstado(Estado: Estado)
  {
    const params = new HttpParams().set('sigla', Estado.sigla);
    return this.http.post<any[]>(`${environment.api}/api/estados/procuraPorUm`, params);
  }

  public destroy(id) {
    return this.http.delete<any>(`${environment.api}/api/estados/${id}`);
  }

  public update(Estado: Estado)
  {
    return this.http.post<any[]>(`${environment.api}/api/estados/update`, Estado);
  }

  public getAll()
  {
    return this.http.get<EstadoResource>(`${environment.api}/estados/lista`);
  }

}
